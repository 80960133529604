<template lang="pug">
  .page.cart.max-width
    .cart-header
      div
        h1 Cart
        p
          | There {{ cartSize === 0 || cartSize > 1 ? 'are' : 'is' }}
          | {{ cartSize }} item
          span(v-if='cartSize === 0 || cartSize > 1') s
          |  in your cart.
    .cart-content
      .left
        cart-component
        .empty(v-if="cart.lineItems && cart.lineItems.length > 0")
          a(
            href="#"
            @click.prevent="$store.dispatch('cart/emptyCart')"
            v-bind:class="{ 'removing': emptyingCart }"
          ) {{ emptyingCart ? 'Emptying Cart' : 'Empty Cart' }}
      subtotal-checkout(:is-cart='true')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartComponent from '@/components/CartComponent'
import SubtotalCheckout from '@/components/SubtotalCheckout'

export default {
  name: 'Cart',

  components: {
    CartComponent,
    SubtotalCheckout
  },

  computed: {
    ...mapState('cart', [
      'cartId',
      'cart',
      'emptyingCart'
    ]),

    ...mapGetters('cart', [
      'cartSize'
    ])
  },

  beforeMount () {
    if (this.cartId === '') {
      this.$store.dispatch('cart/createCart')
    } else {
      this.$store.dispatch('cart/fetchCart')
    }
  }
}
</script>

<style lang="scss" scoped>
.cart {
  .cart-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .cart-header-info {
      padding-top: 0.3em;
      padding-left: 0.5em;
      text-align: right;

      .button {
        margin-bottom: 0.4em;
      }
    }
  }

  .cart-content {
    display: flex;
    flex-flow: column-reverse;

    .left {
      margin-top: 1.25em;
    }

    @media (min-width: $bp-s) {
      display: grid;
      grid-template-columns: 1.35fr 1fr;

      .left {
        margin-top: 0;
        margin-right: 1.25em;
      }
    }

    .empty {
      font-size: 0.8em;
      margin: 1rem 0;
    }
  }

  /deep/ .cart-is-empty {
    align-items: flex-start;
    margin: 0;

    h3 {
      display: none;
    }
  }
}
</style>
